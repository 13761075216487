.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-container {
    width: 100%;
    background-color: white;
    font-family: 'Nunito Sans', sans-serif;
    padding: 40px 20px 20px 20px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 50vh;
    max-width: 350px;
    overflow-y: auto; /* Enables vertical scrolling if content overflows */
  }

  
  .modal-close-button {
    float: right;
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Add mobile styling */
  @media (max-width: 768px) {

    .modal-close-button img {
      width: 15px; /* Reduce image size for smaller screens */
    }
  }