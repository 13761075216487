.dropzone {
    border: 2px dashed #00ff5e;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    transition: border .24s ease-in-out;
  }
  
  .dropzone.active {
    border-color: #28a745;
  }
  
  .upload-instructions {
    font-size: 16px;
    color: #6c757d;
  }
  