.bonus-report {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 10px;
  font-family: 'Nunito Sans', sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 70px;
}

.calendar-section {
  flex: 1;
  text-align: center;
  padding-top: 10px;
}

.stats-section {
  flex: 1;
  text-align: center;
}

.bonus-status {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.status-item {
  flex: 1 1 50%;
  margin-bottom: 20px;
  text-align: center;
}

.on-track {
  color: green;
}

.not-on-track {
  color: red;
}

.details {
  margin-top: 20px;
  text-align: center;
}

.details div {
  margin-bottom: 10px;
}

.details h3 {
  margin: 0;
  font-size: 1em;
}

.circle-chart {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0, 100;
  }
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}

.status-item h3,
  .status-item p {
    font-size: 1.5em;
}

@media (max-width: 768px) {
  .bonus-report {
    padding: 10px;
  }

  .status-item {
    flex: 1 1 100%;
  }

  .stats-section {
    padding-top: 20px;
  }

  .circle-chart {
    width: 80px;
    height: 80px;
  }

  .report-title {
    font-size: 3.8em;
  }

  .details h3 {
    font-size: 0.8em;
  }

  .details div {
    font-size: 0.8em;
  }

  .status-item h3,
  .status-item p {
    font-size: 1.5em;
  }

  .percentage {
    font-size: 0.4em;
  }
}

@media (min-width: 769px) {
  .bonus-report {
    display: grid;
    grid-template-columns: 1fr 2fr; /* Adjust the ratio as needed */
    gap: 20px;
  }

  .calendar-section {
    text-align: left;
  }

  .stats-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .bonus-status {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }

  .status-item {
    flex: none;
    margin-bottom: 0;
    text-align: center;
  }
}
