/* EmployeeHub.css */

.employee-hub-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

.content-container {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 70px; /* Adjust based on the height of the bottom nav */
}
