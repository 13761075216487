.employee-dashboard-container {
    padding: 20px;
    
}

.clock-in-out-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.clock-out-button {
    background-color: #F44336;
}

.error-message {
    color: red;
    margin-top: 10px;
}

.missing-title {
    font-size: larger;
}

.modal-content {
    padding: 20px;
}

.missing-list {
    text-align: left; /* Aligns the list to the left */
    padding-left: 20px; /* Optional: adds padding to the left */
    padding-bottom: 10%;
}

.specialist-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px;
    overflow-y: auto;
    max-height: 400px; /* Adjust height as needed */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
}

.specialist-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
    max-width: 120px;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Nunito Sans', sans-serif;
    margin-top: 10px;
}

.specialist-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.specialist-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
}

.mobile-icon {
    width: 20px;
    height: 20px;
    margin-top: 4px;
}
