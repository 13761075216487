/* MobileNav.css */
.mobile-nav {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #1d9100;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 1000;
}

.mobile-nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 12px;
}

.mobile-nav-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
}

.mobile-selected {
    color: rgb(159, 255, 170);
}

@media (min-width: 769px) {
    .mobile-nav {
        display: none;
    }
}