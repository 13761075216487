body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif; /* Updated font family */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* Keep your other styles as they are */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
