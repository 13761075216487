.bin-tags-container {
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Nunito Sans', sans-serif;
    position: relative;
}

.bin-tags-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.add-bin-tag-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.add-bin-tag-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    border: 2px dashed #ccc;
    border-radius: 15px;
    background-color: #f8f8f8;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-bin-tag-button:hover {
    background-color: #e8e8e8;
}

.add-bin-tag-content {
    display: flex;
    align-items: center;
}

.add-icon {
    margin-right: 10px;
}

.bin-tags-list {
    overflow-x: auto;
    white-space: nowrap;
}

.bin-tag {
    display: inline-block;
    width: calc(25% - 10px);
    margin: 0 5px;
    vertical-align: top;
    text-align: center;
    position: relative;
}

.qr-code {
    width: 100%;
    max-width: 256px;
    height: auto;
}

.qr-code-large {
    width: 156px;
    height: 156px;
    margin-bottom: 20px;
}

.status-indicator {
    position: absolute;
    bottom: 15px;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #fff;
}

.status-indicator.active {
    background-color: #4caf50;
}

.status-indicator.inactive {
    background-color: #f44336;
}

.bin-tag-name {
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
}

.bin-tag-details {
    text-align: center;
}

.modal-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.modal-form label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.modal-form input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Nunito Sans', sans-serif;
}

.modal-form button {
    border-radius: 40px;
    border: 1px solid #C0C0C0;
    background-color: #4caf50;
    font-size: 15px;
    text-align: center;
    transition: all 0.25s;
    cursor: pointer;
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    padding: 10px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.modal-form button:hover {
    background-color: #45a049;
}

.switch {
    margin-left: 2%;
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #4caf50;
}

input:checked + .slider:before {
    transform: translateX(20px);
}

.edit-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.delete-button {
    border-radius: 40px;
    border: 1px solid #C0C0C0;
    background-color: #f44336;
    font-size: 15px;
    text-align: center;
    transition: all 0.25s;
    cursor: pointer;
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    padding: 10px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.save-button, .view-all-button {
    border-radius: 40px;
    border: 1px solid #C0C0C0;
    background-color: #4caf50;
    font-size: 15px;
    text-align: center;
    transition: all 0.25s;
    cursor: pointer;
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    padding: 10px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.save-button:hover {
    background-color: #45a049;
}


.delete-button:hover {
    background-color: #e53935;
}

.view-all-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}


.view-all-button:hover {
    background-color: #0056b3;
}

/* Add the necessary styles for the copy button and success message */
.copy-url {
    margin-top: 20px;
    text-align: center;
}

.copy-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.copy-success {
    margin-left: 10px;
    color: #4caf50;
    font-weight: bold;
}

