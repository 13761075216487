.employee-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.employee-image {
    margin-bottom: 20px;
}

.employee-username {
    text-align: center;
    margin-bottom: 15px;
}

.employee-profile-pic {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.employee-details {
    width: 100%;
    text-align: center;
}

.employee-details p {
    margin: 10px 0;
}

.icon-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    position: static;
}

.edit-icon,
.add-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.add-team-button-container {
    position: static;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .employee-profile-container {
        flex-direction: row;
        align-items: flex-start;
    }

    .employee-image {
        margin-bottom: 0;
        margin-right: 20px;
    }

    .employee-username {
        text-align: left;
    }

    .employee-details {
        text-align: left;
    }

    .icon-container {
        position: absolute;
        top: 10px;
        right: 10px;
        margin-top: 0;
    }

    .add-team-button-container {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
}