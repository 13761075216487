.monthly-reports-container {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    position: relative;
}

.report-section {
    padding: 0;
}

.report-title {
    margin: 0 0 10px 0;
    font-size: 20px;
    font-weight: bold;
}

.month-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.month-button {
    border-radius: 40px;
    border: 1px solid #C0C0C0;
    background-color: #34C759;
    font-size: 15px;
    text-align: center;
    transition: all 0.25s;
    cursor: pointer;
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    padding: 10px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.data-section {
    background-color: #fff;
    padding: 0;
}

.data-title {
    margin: 0 0 10px 0;
    font-size: 18px;
    font-weight: bold;
}

.data-list {
    list-style-type: none;
    padding: 0;
    margin: 0 0 20px 0;
}

.data-item {
    padding: 5px 0;
}

.monthly-report-title {
    margin: 0 0 10px 0;
    font-size: 18px;
    font-weight: bold;
}

.monthly-report {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.report-detail {
    margin: 0;
    font-size: 16px;
}
