/* Clock.css */
.clock-in-container, .clock-out-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    max-width: 500px;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Nunito Sans', sans-serif;
    margin-top: 20px;
}

.community-selector {
    margin-top: 20px;
    position: relative;
    display: inline-block;
}

.dropdown-link {
    color: #1D9100;
    text-decoration: underline;
    cursor: pointer;
}

.dropdown-options {
    position: absolute;
    top: 25px;
    left: 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    background: white;
    width: 200px;
    z-index: 1000;
}

.dropdown-option {
    padding: 10px;
    cursor: pointer;
}

.dropdown-option:hover {
    background: #f0f0f0;
}

.clock {
    margin-top: 20px;
    text-align: center; /* Add this line */
}

.time {
    font-size: 3rem;
    display: block; /* Add this line */
}

.date {
    font-size: .85rem;
    display: block; /* Add this line */
}

.clock-in-button {
    margin-top: 5%;
    padding: 10px 20px;
    background: linear-gradient(#1D9100, #166F00);
    color: white;
    height: 150px;
    width: 150px;
    border: none;
    border-radius: 50%; /* This keeps the button round */
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
}

.clock-in-button:disabled {
    background: linear-gradient(#a4a4a4, #4d4d4d);
    cursor: not-allowed;
    border-radius: 50%; /* Ensures the button stays round */
}


.error-message {
    color: red;
    margin-top: 10px;
}

.clock-out-container {
    text-align: center;
    margin-top: 50px;
}

.employee-hero {
    font-size: 1.5rem;
}

.clock-out-button {
    padding: 10px 20px;
    background-color: #F44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.clock-in-timer {
    margin-top: 20px;
    font-size: 24px;
}

.mobile-logo {
    display: none;
}

.clock-out-button {
    margin-top: 5%;
    padding: 10px 20px;
    background: linear-gradient(#ff4631, #c41f1f);
    color: white;
    height: 150px;
    width: 150px;
    border: none;
    border-radius: 50%; /* This keeps the button round */
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
}

@media (max-width: 768px) {
    .clock-in-button {
        margin-top: 10%;
    }
    
    .clock-in-button:disabled {
        margin-top: 10%;
    }

    .mobile-logo {
        display: block;
        width: 70px;
        height: auto;
        margin-bottom: 20px;
    }

}