.hub-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px;
    flex-wrap: wrap; /* Wrap items to next line on small screens */
}

.hub-item.daily-report {
  background-color: #3CB371; /* A shade of green for the Daily Report box */
  width: calc(100% - 20px); /* Full width minus margin */
  color: white; /* White text color */
}

/* Ensure the other items wrap properly in a row */
.hub-container .hub-item:not(.daily-report) {
  flex: 1 1 200px; /* Flex-grow, flex-shrink, flex-basis */
}

  .hub-item.clock-in-outs {
    background-color: #FFD700; /* Gold color for Clock In-Outs */
  }
  
  .hub-item.services {
    background-color: #ADD8E6; /* Blue color for Services */
  }
  
  .hub-item.units {
    background-color: #90EE90; /* Green color for Units */
  }
  
  .hub-item.violations {
    background-color: #FFC0CB; /* Pink color for Violations */
  }
  
  .hub-item {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    width: 200px; /* Adjust the width as needed */
    height: 200px; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.2em; /* Adjust the font size as needed */
  }
  
  .hub-item:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    transform: translateY(-5px);
  }
  