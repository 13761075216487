/* ProfileInfo.css */

.profile-info-container {
    display: flex;
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    position: relative;
    flex-direction: column;
}

.property-title {
    margin: 0 0 20px 0;
    text-align: left;
}

.property-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.left-column, .right-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 200px;
}

.property-details p {
    margin: 5px 0;
}

.property-details a {
    color: inherit;
    text-decoration: none;
}

.edit-icon {
    position: absolute;
    top: 20px;
    right: 15px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.modal-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modal-form label {
    display: flex;
    flex-direction: column;
}

.modal-form input,
.modal-form button {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Nunito Sans', sans-serif;
}

.modal-form button {
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
}

.modal-form button:hover {
    background-color: #45a049;
}

.blurred {
    filter: blur(5px);
}

/* Responsive styling */
@media (max-width: 768px) {
    .profile-info-container {
        flex-direction: column;
    }

    .property-details {
        flex-direction: column;
    }
}
