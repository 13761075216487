.all-bin-tags-container {
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Nunito Sans', sans-serif;
}

.bt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.bin-tags-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.bin-tags-table th, .bin-tags-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.bin-tags-table th {
    background-color: #f4f4f4;
    cursor: pointer;
}

.batch-actions {
    display: flex;
    justify-content: space-between;
}

.delete-button, .activate-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.delete-button:hover {
    background-color: #e53935;
}

.activate-button:hover {
    background-color: #45a049;
}
