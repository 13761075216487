.property-details-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 20px;
    font-family: 'Nunito Sans', sans-serif;
    padding: 20px;
}

.content-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.monthly-reports-box,
.key-data-box,
.bintag-info-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-info-box,
.monthly-reports-box,
.key-data-box,
.bintag-info-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
    .content-section {
        grid-template-columns: 1fr;
    }
}