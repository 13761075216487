.wf-community {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f8f9fa;
}

.wf-community__container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.wf-community__hero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/hero-background.png') center/cover no-repeat;
  color: #fff;
  text-align: center;
  min-height: 80vh;
  align-content: center;
}

.wf-community__hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.wf-community__hero-title {
  font-size: 3rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.wf-community__hero-subtitle {
  font-size: 1.2rem;
  margin-bottom: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.wf-community__button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.wf-community__button {
  padding: 12px 24px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.wf-community__button--primary {
  background-color: #1D9100;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
}

.wf-community__button--primary:hover {
  background-color: #156900;
}

.wf-community__button--secondary {
  background-color: black;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
}

.wf-community__button--secondary:hover {
  background-color: #fff;
  color: #007bff;
}

.wf-community__status-bar {
  background: linear-gradient(to right, #1a5928, #2e8b57);
  color: #fff;
  padding: 15px 20px;
}

.wf-community__status-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.wf-community__status-label {
  font-weight: bold;
  white-space: nowrap;
}

.wf-community__status-indicator {
  padding: 5px 10px;
  border-radius: 15px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.2);
  min-width: 150px;
  text-align: center;
}

.wf-community__check-status-button {
  padding: 5px 15px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.wf-community__check-status-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.wf-community__status-details {
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: 0.9em;
  margin-top: 10px;
}

.wf-community__main {
  flex: 1;
  padding: 50px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.wf-community__section {
  margin-bottom: 60px;
  text-align: center;
}

.wf-community__section-title {
  font-size: 2rem;
  margin-bottom: 30px;
}

.wf-community__section-content {
  max-width: 800px;
  margin: 0 auto 20px;
}

.wf-community__service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.wf-community__service-card {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.wf-community__service-card:hover {
  transform: translateY(-5px);
}

.wf-community__service-icon {
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 20px;
}

.wf-community__service-title {
  margin-bottom: 15px;
}

.wf-community__list {
  list-style-type: none;
  padding: 0;
  max-width: 800px;
  margin: 0 auto;
}

.wf-community__list-item {
  margin-bottom: 10px;
}

.wf-community__testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1000px;
  margin: 0 auto;
}

.wf-community__testimonial-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.wf-community__testimonial-text {
  font-style: italic;
  margin-bottom: 10px;
}

.wf-community__testimonial-author {
  font-weight: bold;
}

.wf-community__footer {
  background-color: #333;
  color: #fff;
  padding: 50px 20px;
  text-align: center;
}

.wf-community__footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.wf-community__footer-section {
  flex: 1;
  min-width: 200px;
  margin-bottom: 30px;
}

.wf-community__footer-title {
  margin-bottom: 15px;
}

.wf-community__footer-list {
  list-style-type: none;
}

.wf-community__footer-item {
  margin-bottom: 10px;
}

.wf-community__footer-link {
  color: #fff;
  text-decoration: none;
}

.wf-community__social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.wf-community__social-icon {
  font-size: 1.5rem;
}

.wf-community__copyright {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.wf-community__contact-form {
  max-width: 600px;
  margin: 0 auto 40px;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.wf-community__form-group {
  margin-bottom: 20px;
}

.wf-community__form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.wf-community__form-group input,
.wf-community__form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.wf-community__form-group textarea {
  height: 150px;
  resize: vertical;
}

.wf-community__contact-form .wf-community__button {
  width: 100%;
  margin-top: 10px;
}

.wf-community__contact-info {
  text-align: center;
  margin-top: 30px;
}

.wf-community__contact-info p {
  margin-bottom: 10px;
}

/* General responsive adjustments */
@media (max-width: 768px) {
  .wf-community__hero-title {
    font-size: 2rem;
  }

  .wf-community__hero-subtitle {
    font-size: 1rem;
  }

  .wf-community__button-group {
    flex-direction: column;
    align-items: center;
  }

  .wf-community__button {
    width: 100%;
    max-width: 250px;
    margin-bottom: 10px;
  }

  .wf-community__section-title {
    font-size: 1.5rem;
  }

  .wf-community__service-grid,
  .wf-community__testimonial-grid {
    grid-template-columns: 1fr;
  }

  .wf-community__footer-content {
    flex-direction: column;
    align-items: center;
  }

  .wf-community__footer-section {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .wf-community__status-bar {
    flex-direction: column;
    text-align: center;
  }

  .wf-community__status-content {
    flex-direction: column;
    gap: 10px;
  }

  .wf-community__status-label,
  .wf-community__status-indicator,
  .wf-community__check-status-button,
  .wf-community__last-checked {
    margin: 5px 0;
  }
}


