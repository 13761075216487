/* Team.css */
.search-container {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    padding: 10px 0;
}

.team-member-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    transition: box-shadow 0.3s;
    cursor: pointer;
}

.profile-pic-grid {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.team-member-card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.team-member-card.unsaved {
    border-color: red;
}

.team-member-card img {
    width: 50px;
    height: 50px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1040;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.save-button {
    border-radius: 40px;
    border: 1px solid #C0C0C0;
    background-color: #4caf50;
    font-size: 15px;
    text-align: center;
    transition: all 0.25s;
    cursor: pointer;
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    padding: 10px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


.modal-form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* This ensures space between form elements */
}

.modal-form label {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns the label text above the form field */
}

.modal-form label > *:first-child {
    margin-bottom: 5px; /* Adds space between the label text and the form field */
}

.modal-form input[type="file"] {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
}

.modal-form button:hover {
    background-color: black;
}

.delete-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: darkred;
  }
  

  @media screen and (max-width: 768px) {

    .team-member-card {
        padding: 10px;
        font-size: 14px;
    }

    .profile-pic-grid {
        width: 40px;
        height: 40px;
    }

    .search-container {
        margin: 10px;
    }
}

@media screen and (max-width: 480px) {

    .team-member-card {
        padding: 8px;
        font-size: 12px;
    }

    .profile-pic-grid {
        width: 35px;
        height: 35px;
    }
}