/* Logs.css */

.employee-container {
    padding: 20px;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: auto; /* Allow vertical scrolling */
    font-family: 'Nunito Sans', sans-serif;
}

.filter-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.filter-dropdown {
    margin-left: auto; /* Push the dropdown to the right */
}

.filter-container label {
    font-weight: bold;
}

.filter-container select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    height: 55px;
}

.logs-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.log-item {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-wrap: break-word;
    position: relative;
}

.log-item h3 {
    margin-top: 0;
    text-transform: capitalize;
}

.log-item p {
    margin: 10px 0;
}

.log-item img {
    width: 50px;
    height: 50px;
    float: right;
    border-radius: 5px;
}

@media (min-width: 600px) {
    .logs-grid {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}
