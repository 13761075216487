/* Community.css */
.community-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    font-family: 'Nunito Sans', sans-serif;
    padding: 20px;
    background-color: #f4f4f4;
    overflow-y: auto;
}

.search-container {
    margin: 20px; /* Space above the grid */
    display: flex;
    justify-content: center; /* Centering the search input container */
    position: sticky; /* Keeps the search bar at the top when scrolling */
    top: 0; /* Align to the top */
    background-color: white; /* Background color to overlap grid content */
    z-index: 10; /* Ensures it's above other content */
    padding: 10px 0; /* Padding to ensure it looks good */
}

.property-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    max-height: 5vh;
    cursor: pointer;
    background-color: white;
    transition: box-shadow 0.3s, border-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }  

.property-card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.property-card.unsaved {
    border-color: red; /* Red border for unsaved properties */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1040;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-close-button {
    position: absolute;
    top: 5px;
    right: 10px;
    border: none;
    background: none;
    font-size: .5rem;
    cursor: pointer;
}

.modal-form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* This ensures space between form elements */
}

.modal-form label {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns the label text above the form field */
}

.modal-form label > *:first-child {
    margin-bottom: 5px; /* Adds space between the label text and the form field */
}

.modal-form input[type="number"], 
.modal-form input[type="file"], 
.modal-form input[type="email"], 
.modal-form input[type="tel"], 
.modal-form input[type="text"], 
.modal-form select {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    width: 95%; /* Ensures input fields use the full width */
}

.address-input {
    width: 95%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.pac-container {
    z-index: 1060 !important; /* Ensures the dropdown appears above the modal */
}
  

.modal-form button {
    border-radius: 40px;
    border: 1px solid #C0C0C0;
    color: white;
    background-color: #1d9100;
    padding: 10px 20px;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.25s;
}

.modal-form button:hover {
    background-color: #0a5700; /* Darker green on hover */
}

.month-button {
    border-radius: 40px;
    border: 1px solid #C0C0C0;
    background-color: #34C759;
    margin: 10px; /* Spacing between buttons */
    font-size: 15px; /* Font size inside buttons */
    text-align: center; /* Centered button text */
    transition: all 0.25s; /* Transition for hover effect */
    cursor: pointer; /* Cursor pointer when hovering */
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    display: inline-block; /* Display as inline-block */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 20px; /* Padding inside buttons */
}

.month-button:nth-child(1) {
    background-color: #2ECC71; /* Lighter bright green */
}

.month-button:nth-child(2) {
    background-color: #34C759; /* Medium bright green */
}

.month-button:nth-child(3) {
    background-color: #4CD964; /* Darker bright green */
}

.property-details-container {
    font-family: 'Nunito Sans', sans-serif;;
    margin: 20px;
}

@media screen and (max-width: 768px) {
    .community-container {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 10px;
        padding: 10px;
    }

    .property-card {
        padding: 10px;
        font-size: 14px;
    }

    .search-container {
        margin: 10px;
    }
}