.button {
    border-radius: 40px;
    border: 1px solid #C0C0C0;
    margin: 10px; /* Spacing between buttons */
    font-size: 15px; /* Font size inside buttons */
    text-align: center; /* Centered button text */
    transition: all 0.25s; /* Transition for hover effect */
    cursor: pointer; /* Cursor pointer when hovering */
    display: inline-block; /* Display as inline-block */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 20px; /* Padding inside buttons */
}

.modal-content {
    text-align: center; /* Centers everything in the modal */
}

.button-group {
    display: flex;
    justify-content: center; /* Centers button group horizontally */
    flex-wrap: wrap; /* Allows buttons to wrap to the next line */
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* adjust the height to your liking */
    margin-top: 20px; /* add some space between the table and the button */
}

.error-text {
    color: red; /* Sets the text color to red */
}

/* Data input page styling */
.data-input-page {
    padding: 20px; /* Adds padding around the entire content */
}
/* Specific button colors */
.button.violations { background-color: #FFC0CB; } /* Example pink color for Violations */
.button.services { background-color: #ADD8E6; } /* Example blue color for Services */
.button.units { background-color: #90EE90; } /* Example green color for Units */
.button.clock-in-outs { background-color: #FFD700; } /* Example gold color for Clock In-Outs */

/* Hover effect */
.button:hover {
    opacity: 0.9; /* Slight transparency on hover */
}

/* Inside your DataInputPage.css */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust height as needed */
  }

  .log-section table {
    width: 100%;
    border-collapse: collapse;
}

.active-log {
    margin-top: 5%;
}

.log-section th, .log-section td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.log-section th {
    background-color: #f4f4f4;
}

.show-more-button {
    border-radius: 40px;
    color: white;
    font-family: 'Nunito Sans', sans-serif;;
    border: 1px solid #C0C0C0;
    background-color: #1d9100;
    margin: 10px; /* Spacing between buttons */
    font-size: 15px; /* Font size inside buttons */
    text-align: center; /* Centered button text */
    transition: all 0.25s; /* Transition for hover effect */
    cursor: pointer; /* Cursor pointer when hovering */
    display: inline-block; /* Display as inline-block */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 20px; /* Padding inside buttons */
}
  