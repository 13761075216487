/* src/components/MobileNav.css */
.mobile-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  border-top: 1px solid #ccc;
  padding: 10px 0; /* Adjust as needed */
  z-index: 1000; /* Ensure it stays on top */
}

.mobile-menu a {
  flex: 1;
  text-align: center;
}

.mobile-menu img {
  width: 26px;
  height: 26px;
}

.mobile-menu .active img {
  filter: invert(63%) sepia(99%) saturate(696%) hue-rotate(85deg) brightness(93%) contrast(91%);
}
