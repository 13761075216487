.auth-container {
    display: flex;
    min-height: 100vh;
    background-color: #ffffff;
    font-family: 'Nunito Sans', sans-serif; 
}

.artwork-section {
    flex: 1;
    background-image: url('../../assets/hero-background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 30px 0 0 30px;
}

.login-side {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 400px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid #2bd500;
}

.input-login {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.login-title {
    font-size: 1rem;
}

.error-message {
    color: red;
    margin: 10px 0;
}

.logo {
    width: 20%;
    max-width: 100px;
}

.auth-label {
    width: 100%;
    margin: 5px 0;
}

.google-button {
    display: flex;
    justify-content: center;
    color: black;
    background-color: #fff;
    align-items: center;
    margin-top: 10px;
}
  
.google-logo {
    margin-right: 10px;
    max-height: 20px;
}
  

@media (max-width: 768px) {
    .auth-container {
        flex-direction: column;
    }

    .artwork-section {
        display: none;
    }
}

a {
    cursor: pointer;
    color: #141E4F;
    text-decoration: none;
}
