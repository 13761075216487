.table {
    height: 100%;
}

.rs-picker-dropdown .rs-picker-menu {
    max-height: 200px; /* Adjust based on your needs */
    overflow-y: auto; /* Enables vertical scrolling */
}

.rs-datagrid .datagrid-header-cell {
  white-space: nowrap;
  overflow: visible;
  text-overflow: clip;
}

.search-input {
    flex: 1;
    height: 30px; /* Reduced height */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 50px; /* Rounded corners */
    padding: 8px 15px; /* Reduced padding */
    margin-right: 20px; /* Maintain margin */
    margin-left: 10px;
    outline: none; /* Remove focus outline */
  }
  
  .search-input:focus {
    border-color: #007bff; /* Focus color */
  }
  