.web-nav {
    display: flex;
    justify-content: center; /* Align items to the center of the nav bar */
    align-items: center;
    padding: 10px 0;
    background-color: #fff;
  }
  
  .web-nav .logo-link {
    display: flex; /* Flex container for the logo to center it vertically */
    justify-content: center;
    align-items: center;
    text-decoration: none; /* No underline */
  }
  
  .web-nav a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    margin: 0 5px; /* Adjust margins to control spacing further */
  }
  
  .web-nav .active {
    color: #1D9100;
  }
  
  .web-nav .logo-link {
    text-decoration: none; /* Ensures no underline appears */
  }

  .logo-employee {
    width: 25%;
  }
  
  