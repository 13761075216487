.dashboard {
    display: flex;
    height: 100vh;
}

.dashboard-content {
    flex: 1;
    overflow-y: auto;
}

.dashboard-mobile .dashboard-content {
    padding-bottom: 80px; /* Add space for the mobile navigation */
}

@media (max-width: 768px) {
    .dashboard {
        flex-direction: column;
    }

    .side-nav {
        display: none; /* Hide side nav on mobile */
    }
}