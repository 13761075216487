.wastefree-header-community {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  position: relative;
}

.wastefree-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.wastefree-logo {
  display: flex;
  align-items: center;
}

.wastefree-logo img {
  height: 30px;
}

.wastefree-nav {
  display: flex;
  align-items: center;
}

.wastefree-nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.wastefree-nav-item {
  margin-right: 20px;
}

.wastefree-nav-item a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.wastefree-nav-button {
  background-color: black;
  color: white !important;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.wastefree-menu-icon {
  display: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .wastefree-header-container {
    flex-wrap: wrap;
  }

  .wastefree-logo {
    width: 50%;
  }

  .wastefree-menu-icon {
    display: block;
    margin-left: auto;
  }

  .wastefree-nav {
    display: none;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .wastefree-nav.open {
    display: block;
  }

  .wastefree-nav-list {
    flex-direction: column;
    padding: 20px;
  }

  .wastefree-nav-item {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .wastefree-nav-button {
    display: inline-block;
    margin-top: 10px;
  }
}