/* DailyReportsPage.css */
.update-report-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .update-report-btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* adjust the height to your liking */
    margin-top: 20px; /* add some space between the table and the button */
}

.update-button {
    border-radius: 40px;
    color: white;
    font-family: 'Nunito Sans', sans-serif;;
    border: 1px solid #C0C0C0;
    background-color: #1d9100;
    margin: 10px; /* Spacing between buttons */
    font-size: 15px; /* Font size inside buttons */
    text-align: center; /* Centered button text */
    transition: all 0.25s; /* Transition for hover effect */
    cursor: pointer; /* Cursor pointer when hovering */
    display: inline-block; /* Display as inline-block */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 20px; /* Padding inside buttons */
}

.table {
  height: 100%;
  width: 100%;
}

.search-input {
  padding: 8px;
  width: 200px;
}

.rsuite .rs-picker-toggle {
  width: 100%;
}
