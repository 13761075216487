.team-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 100vh;
    gap: 20px;
    box-sizing: border-box;
}

.profile-info-section {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fbd9b1; /* Match the color of the profile element */
    padding: 20px;
    border-radius: 10px;
    flex: 1;
    box-sizing: border-box;
}

.profile-pic-profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
}

.profile-details {
    flex: 1;
}

.profile-details h2 {
    margin: 0;
    font-size: 2em;
}

.profile-details p {
    margin: 5px 0;
    font-size: 1.2em;
}

.reports-section {
    width: 100%;
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    flex: 2;
}

.monthly-reports-box, .key-data-box, .bintag-info-box {
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    font-size: 1.2em;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.monthly-reports-box {
    background-color: #ffffb3; /* Match the color of the monthly reports */
}

.key-data-box {
    background-color: #ffc1c1; /* Match the color of the key data box */
}

.team-details-container {
    padding: 20px;
}

.reports-section {
    margin-top: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.specialists-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 24px;
    max-width: 500px;
    width: 100%;
    border-radius: 8px;
}

.search-results {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
