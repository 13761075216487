.animated-tabs {
    display: flex;
    position: relative;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .tab {
    flex: 1;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .tab.active {
    font-weight: bold;
  }
  
  .animated-tab-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 50%;
    background-color: red;
    transition: left 0.3s, background-color 0.3s;
  }
  
  .tab:nth-child(2).active ~ .animated-tab-indicator {
    left: 50%;
    background-color: green;
  }
  