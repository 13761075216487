.employee-toolkit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    max-width: 500px;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Nunito Sans', sans-serif;
    margin-top: 20px;
}

.tool-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

.tool {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
    min-width: max-content;
    transition: background-color 0.3s, border-color 0.3s;
    aspect-ratio: 1; /* Ensure the tool is square */
}

.tool.completed {
    border-color: green;
    background-color: #eaffea;
}

.tool:hover {
    background-color: #f7f7f7;
}

.tool img {
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    margin-bottom: 10px; /* Add margin below the icon */
}

.tool p {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: bold;
}


/*Trash CSS*/

.trash-compactor-photos {
    text-align: center;
    margin-top: 20px;
}

.trash-compactor-photos h2 {
    font-size: 18px;
    margin-bottom: 10px;
}

.trash-compactor-photos p {
    margin-bottom: 10px;
}

.photo-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.photo-preview img {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
}

.camera-view {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video-feed {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.hidden-canvas {
    display: none;
}

.drop-down {
    margin-top: 10px;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 10px; /* Add some space between buttons */
    margin-top: 10px;
}

select {
    margin-top: 10px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Nunito Sans', sans-serif;
}

/*Violations CSS*/

.violations-form-container {
    padding: 10px;
}

.violations-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 400px;
}

.violations-form label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%; /* Ensure labels take full width */
}

.violations-form input,
.violations-form select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Nunito Sans', sans-serif;
    width: 100%; /* Ensure inputs and selects take full width */
    box-sizing: border-box; /* Include padding and border in element's width */
}

.violations-form button {
    border-radius: 40px;
    border: 1px solid #C0C0C0;
    background-color: #4caf50;
    font-size: 15px;
    text-align: center;
    transition: all 0.25s;
    cursor: pointer;
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    padding: 10px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.violations-form button:hover {
    background-color: #45a049;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px; /* Reduce width */
    height: 14px; /* Reduce height */
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    max-width: 40px;
    border-radius: 14px; /* Adjust border radius */
}

.slider:before {
    position: absolute;
    content: "";
    height: 10px; /* Reduce height */
    width: 10px; /* Reduce width */
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #4caf50;
}

input:checked + .slider:before {
    transform: translateX(20px); /* Adjust translation */
}

/* BinTag Style */

.bin-tag-scan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px;
}

.bin-tag-scan-container p {
    font-size: 18px;
    margin-top: 20px;
}

.error-message {
    color: red;
    margin-top: 10px;
}

.video-feed {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    margin-bottom: 10px;
}
