.side-nav {
  width: 15%;
  background-color: #1d9100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensure it fills the container */
}

.wf-logo-container {
  padding: 20px 0;
  text-align: center;
}

.wf-logo-link {
  display: inline-block;
}

.wf-logo {
  max-width: 60%;
  height: auto;
}

.wf-nav-links {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.wf-nav-link {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  text-decoration: none;
  color: white;
  transition: background-color 0.3s ease;
}

.wf-nav-link:hover, .wf-nav-link.wf-selected {
  background-color: rgba(255, 255, 255, 0.125);
  color: rgb(219, 255, 223);
}

.wf-nav-icon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.wf-nav-link span {
  font-size: 16px;
}

.wf-nav-bottom {
  padding-bottom: 20px;
}

.wf-logout-btn {
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  padding: 15px 20px;
}

@media (max-width: 768px) {
  .wf-side-nav {
    width: 60px;
    min-width: 60px;
  }

  .wf-logo {
    max-width: 80%;
  }

  .wf-nav-link span {
    display: none;
  }

  .wf-nav-icon {
    margin-right: 0;
  }

  .wf-nav-link, .wf-logout-btn {
    justify-content: center;
  }
}