.employee-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: auto; /* Allow vertical scrolling */
    padding: 10px;
    box-sizing: border-box;
  }
  
  .profile-container {
    width: 100%;
    max-width: 600px; /* Max width for better readability */
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 10px;
  }

.profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.profile-pic {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
}

.profile-info {
    flex: 1;
}

.profile-info h1 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
}

.profile-info p {
    margin: 5px 0 0;
    color: #777;
}

.profile-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profile-links h2 {
    margin: 20px 0 10px;
    font-size: 18px;
    color: #333;
}

.profile-link {
    padding: 15px;
    border-radius: 10px;
    background-color: #f5f5f5;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s;
}

.profile-link:hover {
    background-color: #e0e0e0;
}

.profile-link p {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.modal-form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* This ensures space between form elements */
}

.modal-form label {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns the label text above the form field */
}

.modal-form label > *:first-child {
    margin-bottom: 5px; /* Adds space between the label text and the form field */
}

.modal-form input[type="file"] {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
}

.modal-form button:hover {
    background-color: black;
}

.links-modal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
}

.link-item {
    padding: 15px;
    background-color: #d4edda;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.link-item:nth-child(2) {
    background-color: #c3e6cb;
}

.link-item:nth-child(3) {
    background-color: #a2d5a2;
}

.link-item:hover {
    background-color: #91c791;
}

.links-modal button {
    margin-top: 15px;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.links-modal button:hover {
    background-color: #0056b3;
}
