.wf-dashboard-header {
  display: flex;
  font-family: 'Nunito Sans', sans-serif;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 2.5% 0 0.5%;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
}

.wf-dashboard-title h2 {
  padding-left: 10px;
  font-weight: 700;
  font-size: 1.5rem;
}

.wf-dashboard-actions {
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust the spacing as needed */
}

.wf-action-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%);
  display: none; /* Hide icons by default */
}

@media (max-width: 768px) {
  .wf-dashboard-header {
      height: 8vh;
      padding: 0 5%;
  }

  .wf-dashboard-title h2 {
      font-size: 1.2rem;
  }

  .wf-action-icon {
      width: 20px;
      height: 20px;
      display: block; /* Show icons on mobile */
  }
}
