/* src/pages/StatusPage.css */
 .status-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }
  
  .map-container {
    flex: 1;
    width: 100%;
    height: 100%;
  }
  
  .status-report {
    background: white;
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }
  
  .status-report h3 {
    margin: 0 0 10px;
  }
  
  .status-report ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .status-report ul li {
    margin: 5px 0;
  }
  